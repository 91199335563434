@import "../../common/color.scss";

.wordle {
  margin: 10px;
  text-align: center;
  justify-content: center;
  display: flex;

  .letterInput {
    position: relative;
    display: inline-block;
    margin: 4px;
    color: $color-tone-7;
    height: 62px;
    width: 62px;
    line-height: 62px;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 2px;

    &.focusBox::after {
      content: "";
      position: absolute;
      left: -4px;
      right: -4px;
      top: -4px;
      bottom: -4px;
      border: 2px solid;
      border-radius: 4px;
      opacity: 0.8;
    }

    &.grey {
      background-color: $color-tone-2;

      &.focusBox::after {
        border-color: $color-tone-2;
      }
    }

    &.yellow {
      background-color: $color-yellow;

      &.focusBox::after {
        border-color: $color-yellow;
      }
    }

    &.green {
      background-color: $color-green;

      &.focusBox::after {
        border-color: $color-green;
      }
    }
  }
}

.line {
  width: 350px;
  height: 1px;
  background-color: $color-tone-4;
  border: none;
  margin: 0 auto;

  &:first-child {
    display: none;
  }
}
