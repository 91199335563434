$color-green: #6aaa64;
$color-darkGreen: #538d4e;
$color-yellow: #c9b458;
$color-darkYellow: #b59f3b;
$color-lightGray: #d8d8d8;
$color-gray: #86888a;
$color-darkGray: #939598;
$color-white: #fff;
$color-black: #212121;
$color-orange: #f5793a;
$color-blue: #85c0f9;

$color-tone-1: #1a1a1b;
$color-tone-2: #787c7e;
$color-tone-3: #878a8c;
$color-tone-4: #d3d6da;
$color-tone-5: #edeff1;
$color-tone-6: #f6f7f8;
$color-tone-7: #ffffff;
$opacity-50: rgba(255, 255, 255, 0.5);
