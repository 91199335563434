@import "./common/color.scss";

.App {
  text-align: center;

  .candidateList {
    padding: 10px 20px;
    color: $color-tone-3;
    font-size: 1.2rem;
    border-top: solid 1px $color-tone-4;

    .guide {
      font-size: 14px;
      width: 340px;
      text-align: left;
      margin: auto;
      line-height: 24px;
      padding-left: 20px;
    }

    .candidate {
      display: inline-block;
      margin: 3px;
      padding: 0 6px;
      line-height: 24px;
      font-size: 14px;
      background-color: $color-green;
      border-radius: 4px;
      color: white;
      cursor: pointer;
    }
  }
}
