@import "../../common/color.scss";

.guessList {
  display: flex;
  text-align: center;
  justify-content: center;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }

  .guess {
    display: inline-block;
    margin: 4px;
    color: $color-tone-7;
    height: 62px;
    width: 62px;
    line-height: 62px;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 2px;

    &.grey {
      background-color: $color-tone-2;
    }
    &.yellow {
      background-color: $color-yellow;
    }
    &.green {
      background-color: $color-green;
    }
  }
}
